import axios from "axios";

// export const link = 'http://127.0.0.1:8000'
export const link = 'https://api-livestream.digi-screen.com'


// export const linkImage = 'http://127.0.0.1:8000'
export const linkImage = ''


// export const linkHome = 'http://127.0.0.1:8000'
export const linkHome = 'https://api-livestream.digi-screen.com'



const axiosClient = axios.create({
  // baseURL: "http://127.0.0.1:8000/",
  baseURL: "https://api-livestream.digi-screen.com/",


});

export const pagination_size = 10

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("U_T");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});


export default axiosClient;