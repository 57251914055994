import React, { useState, useEffect } from 'react';
import './StreamStyle.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import disque from "../../../../assets/images/disque.png";
import LyricsDisplay from './StreamLyrics';
import WebFont from 'webfontloader';
import { DateTime } from 'luxon';

import { linkHome } from '../../../../../axios-client';
import { lowerCase } from 'lodash';


const NewAnimation = () => {
    const { id } = useParams();
    const idRadio = parseInt(id);

    const [currentSlide, setCurrentSlide] = useState(0);

    const [previousSlide, setPreviousSlide] = useState(0);
    const [currentTransitionEffect, setCurrentTransitionEffect] = useState('fondu');
    const [currentSlideShowEffect, setCurrentSlideShowEffect] = useState('zoom-out');

    const [slideImages, setSlideImages] = useState([]);
    const [logoRadio, setLogoRadio] = useState();
    const [configurationRadio, setConfigurationRadio] = useState();
    const [durationTransition, setDurationTransition] = useState(10);
    const [dataTransitionEffect, setDataTransitionEffect] = useState([]);
    const [dataSlideShowEffect, setDataSlideShowEffect] = useState([]);
    const [etatLoading, setEtatLoading] = useState(true);
    const [slideImagesTime, setSlideImagesTime] = useState([]);
    const [lastTitle, setLastTitle] = useState('');
    const [lastArtiste, setLastArtiste] = useState('');
    const [lastAlbumCover, setLastAlbumCover] = useState('');
    const [streamUrl, setStreamUrl] = useState();
    const [loading, setLoading] = useState(true);

    const [Lyrics, setLyrics] = useState([]);
    const [startTime, setStartTime] = useState("");
    const [nextTitleStartTime, setNextTitleStartTime] = useState(20000);
    const [musicData, setMusicData] = useState([]);

    const delai = 0



    const [lyricsAllowed, setLyricsAllowed] = useState(false);

    const [countPlus, setCountPlus] = useState(1);
    const [nextData, setNextData] = useState(1);


    async function france2() {
        const response = await fetch("https://ns3200830.ip-141-94-133.eu/radioscreen/cache/state/558.json")
        const data = await response.json()
        // console.log("lastTitle ", lastTitle);



        if (data?.tuple?.isMusic === true) {
            if (data?.tuple?.title && lowerCase(data?.tuple?.title) !== lowerCase(lastTitle)) {
                setNextData(nextData + 1)
                console.log("title changed .........", lowerCase(data?.tuple?.title), lowerCase(lastTitle));

            }
        }
        console.log("countPlus .....");

        setCountPlus(countPlus + 1)

    }

    useEffect(() => {
        if (!loading && !etatLoading) {
            setTimeout(() => {
                france2()
            }, 2000);
        }

    }, [countPlus, loading, etatLoading])


    function calculateNextFetchTime(nextTime) {
        const formattedAssetStartTime = nextTime.replace(' ', 'T');
        const startTime = DateTime.fromISO(formattedAssetStartTime, { zone: 'Europe/Paris' }).toMillis();
        const currentTime = DateTime.now().setZone('Europe/Paris').toMillis();
        let elapsed = startTime - currentTime;
        if (elapsed <= 0) {
            // alert("heyyyy")
            elapsed = 20000
        }
        // console.log("lancé : ", startTime, currentTime, nextTime, elapsed);
        // console.log("dans ", (elapsed / 1000) + delai, " secondes");
        setNextTitleStartTime(elapsed + delai)
        return elapsed + delai

    }

    async function updateSlideOnTime(data) {
        console.log("updating slides on time......");
        setLastTitle(data.titre);
        setLastArtiste(data.artist);
        setLastAlbumCover(data.album_cover);
        const filteredLyrics = data.lyrics.filter(lyric => lyric.duration && lyric.milliseconds);
        setLyrics(filteredLyrics)
        setStartTime(data.startTime)
        setPreviousSlide(0)
        setCurrentSlide(0)
        // alert("heyyyyyy")
        // console.log("hey here is my test ..........");
        if (data?.musicImages.length > 0) {
            if (data?.musicImages.length === 1) {
                // concatener avec les liste d'images par defaut
                const array = slideImages.length !== 0 ? data.musicImages.concat(slideImages) : [data.musicImages[0], logoRadio]
                // console.log("array: ", array, slideImages);
                setSlideImagesTime(array);
            } else {
                setSlideImagesTime(data.musicImages);
            }
        } else {
            setSlideImagesTime(slideImages);
        }
    }


    async function musicDataProcessing() {
        try {
            // console.log("nextTitleStartTime", nextTitleStartTime);
            const data = musicData[1] //recuperer la premiere liste de donnée
            // console.log("data: ", data);
            if (data?.titre) {
                console.log("next data: ", data?.titre);

                await updateSlideOnTime(data)
            }

            const response = await fetch(`${linkHome}/getImageByRadio/?radioId=${idRadio}&lastTitle=${lastTitle}`);
            let donnee = await response.json()
            // console.log("donnee[0].nextTitleStartTime", donnee[0].nextTitleStartTime);
            if (donnee[0].titre !== "") {
                setMusicData(donnee)
                calculateNextFetchTime(donnee[0].nextTitleStartTime)
            }
            else {
                // setNextTitleStartTime(5000)
                console.log("calling again");

                musicDataProcessing()

            }
            // console.log("new donnee: ", donnee);
            // refaire la requete 5s apres pour compenser les latence en cas de non actualisation du server client à temps
            // setNextTitleStartTime(5000)

        }
        catch (error) {
            console.log("and error occured", error);
        };


    }

    const fetchDataWithTime = async (default_images) => {
        try {
            const response = await fetch(`${linkHome}/getImageByRadio/?radioId=${idRadio}&lastTitle=${lastTitle}`);
            let data = await response.json()
            setMusicData(data)
            data = data[0] //recuperer la premiere liste de donnée
            if (data.titre && data.isDataChanged) {
                setLastTitle(data.titre);
                setLastArtiste(data.artist);
                setLastAlbumCover(data.album_cover);
                // console.log("data lyrics ", data.lyrics);
                // setLyrics(data.lyrics)
                const filteredLyrics = data.lyrics.filter(lyric => lyric.duration && lyric.milliseconds);
                setLyrics(filteredLyrics)
                setStartTime(data.startTime)

                if (data.nextTitleStartTime !== "")
                    calculateNextFetchTime(data.nextTitleStartTime)


                // console.log("here is the start Time: ", data.startTime);

                setPreviousSlide(0)
                setCurrentSlide(0)

                if (data?.musicImages.length > 0) {
                    if (data?.musicImages.length === 1) {
                        // concatener avec les liste d'images par defaut
                        const array = default_images.length !== 0 ? data.musicImages.concat(default_images) : [data.musicImages[0], logoRadio]
                        // console.log("array: ", array, default_images);
                        setSlideImagesTime(array);
                    } else {
                        setSlideImagesTime(data.musicImages);
                    }
                } else {
                    // alert('ok')
                    // console.log('IMAGE défauts : ',default_images);
                    setSlideImagesTime(default_images);
                }

                setEtatLoading(false);

            } else if (!data.titre && data.isDataChanged) {
                if (data?.musicImages.length) {
                    setSlideImagesTime(data.musicImages);
                } else {
                    setSlideImagesTime(default_images);
                }

                setLastAlbumCover(data.album_cover);
                setSlideImagesTime(default_images)
                // setLyrics(data.lyrics)
                const filteredLyrics = data.lyrics.filter(lyric => lyric.duration && lyric.milliseconds);
                setLyrics(filteredLyrics)
                setStartTime(data.startTime)

                setEtatLoading(false);

            }
        }
        catch (error) {
            console.log("and error occured", error);
        };

    };


    const fetchData = async () => {
        try {
            const logoRes = await axios.get(`${linkHome}/radios/${idRadio}/`);
            setLogoRadio(logoRes.data.logo);
            setLastAlbumCover(logoRes.data.logo)
            setStreamUrl(logoRes.data.audioLinkStream);
            setLyricsAllowed(logoRes.data.allowLyrics)
            console.log("stream link ", logoRes.data.audioLinkStream);

            const imagesRes = await axios.get(`${linkHome}/default_images/${idRadio}/`);
            setSlideImages(imagesRes.data);
            setSlideImagesTime(imagesRes.data || []);
            const configRes = await axios.get(`${linkHome}/current_config/?radio=${idRadio}`);
            setConfigurationRadio(configRes.data);
            setDataTransitionEffect(configRes.data?.transitionEffect);
            setDurationTransition(configRes.data?.transitionDuration);
            setDataSlideShowEffect(configRes.data?.slideShowEffect);
            setCurrentTransitionEffect(configRes.data?.transitionEffect[0]);
            setCurrentSlideShowEffect(configRes.data?.slideShowEffect[0]);

            await fetchDataWithTime(imagesRes.data);

            setLoading(false);


        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };



    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        // if (!loading) {
        // const interval = setInterval(async () => {
        musicDataProcessing();
        // }, nextTitleStartTime);

        // return () => clearInterval(interval);
        // }
    }, [nextData]);


    useEffect(() => {
        if (!loading && !etatLoading && slideImagesTime.length !== 0) {
            const slideInterval = setInterval(() => {
                setPreviousSlide(currentSlide)
                const currentSlideEffect = dataSlideShowEffect[Math.floor(Math.random() * dataSlideShowEffect.length)];
                setCurrentSlideShowEffect(currentSlideEffect);
                // console.log("currentSlideEffect", currentSlideEffect);

                const currentTransitionEffect = dataTransitionEffect[Math.floor(Math.random() * dataTransitionEffect.length)];
                // console.log("currentTransitionEffect", currentTransitionEffect);
                setCurrentTransitionEffect(currentTransitionEffect);

                setCurrentSlide((prevSlide) => {
                    const nextSlide = (prevSlide + 1) % slideImagesTime.length;
                    return nextSlide;
                });
            }, durationTransition * 1000);

            return () => clearInterval(slideInterval);
        }
    }, [durationTransition, loading, slideImagesTime, dataSlideShowEffect, currentSlide]);


    useEffect(() => {
        if (configurationRadio?.titlePolice) {
            WebFont.load({
                google: {
                    families: [configurationRadio?.titlePolice]
                }
            });
        }
    }, [configurationRadio?.titlePolice]);

    useEffect(() => {
        if (configurationRadio?.artistPolice) {
            WebFont.load({
                google: {
                    families: [configurationRadio?.artistPolice]
                }
            });
        }
    }, [configurationRadio?.artistPolice]);


    const renderSlides = () => {
        return slideImagesTime.map((slideImage, index = currentSlide) => {
            return (
                <div style={{ position: 'relative' }} className={``} key={index}>
                    <div
                        className={`${currentTransitionEffect} ${currentSlide === index && `${currentTransitionEffect}-checked`}`}
                    >
                        <div style={{
                            backgroundImage: `url(${slideImagesTime[index]?.replace(/\\/g, '/') || slideImages[0]})`,
                            // background: `yellow`,
                            position: "absolute",
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)',
                            width: "100%",
                            height: "100%",
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            // filter: 'blur(12px)',
                            filter: ` ${configurationRadio?.blackWhiteImage ? 'grayscale(1) blur(12px)' : ' grayscale(0) blur(12px)'}`,

                            zIndex: -3,
                            objectFit: 'cover',
                        }}
                        ></div>

                        <img
                            alt=''
                            className={`image ${currentSlide === index && `image-effect-${currentSlideShowEffect}`}`}
                            // src={slideImagesTime[index]?.replace(/\\/g, '/')}
                            // onError={handleImageError}
                            src={slideImagesTime[index]?.replace(/\\/g, '/') || slideImages[0]}
                            style={{
                                animation: currentSlide === index && ` ${currentSlideShowEffect} ${durationTransition - 2}s forwards`,
                                filter: configurationRadio?.blackWhiteImage ? 'grayscale(1)' : 'grayscale(0)',
                                objectFit: 'contain',
                                position: 'relative',
                                // display: imageLoaded[index] ? 'block' : 'none',
                                zIndex: 1
                            }}
                        />


                        <div className='song-details' style={{
                            position: 'absolute',
                            zIndex: 3,
                            top: configurationRadio?.walletPosition.includes('top') && '1%',
                            left: configurationRadio?.walletPosition.includes('left') && '1%',
                            bottom: configurationRadio?.walletPosition.includes('bottom') && '1%',
                            right: configurationRadio?.walletPosition.includes('right') && '1%',
                            width: `100%`,
                            height: `${configurationRadio?.walletH}%`,
                        }}>
                            <div className='song-image' style={{
                                height: "100%",
                                width: `${configurationRadio?.walletW}%`,
                            }}>
                                <img className='' alt='WALLET' src={lastAlbumCover !== "" ? lastAlbumCover : logoRadio} style={{ height: '100%', width: '100%', borderRadius: 10 }} />
                            </div>
                            <div style={{
                                position: 'relative',
                                width: `20%`,
                                height: '100%',
                            }}>
                                <div className={`song-title-${configurationRadio?.textEffect}`} style={{
                                    position: 'absolute',
                                    top: configurationRadio?.titlePosition.includes('top') && 0,
                                    left: configurationRadio?.titlePosition.includes('left') && 0,
                                    bottom: configurationRadio?.titlePosition.includes('bottom') && 0,
                                    right: configurationRadio?.titlePosition.includes('right') && 0,
                                    transform: (configurationRadio?.titlePosition.includes('center') || configurationRadio?.titlePosition.length === 0) && 'translateY(-50%)',
                                    // top: (configurationRadio?.titlePosition.includes('center') || configurationRadio?.titlePosition.length === 0) && '50%',
                                }}>
                                    <h1 style={{
                                        color: ["aucun", "ombre", "elevation"].includes(configurationRadio?.textEffect) ? configurationRadio?.titleColor : 'transparent',
                                        fontSize: configurationRadio?.titleSize,
                                        fontFamily: configurationRadio?.titlePolice,
                                        WebkitTextStroke: configurationRadio?.textEffect === 'contour' && `1px ${configurationRadio?.titleColor}`,
                                    }}>{configurationRadio?.positionInversion ? lastTitle : lastArtiste}</h1>

                                    <span style={{
                                        color: ["aucun", "ombre", "elevation"].includes(configurationRadio?.textEffect) ? configurationRadio?.artistColor : 'transparent',
                                        fontSize: configurationRadio?.artistSize,
                                        fontFamily: configurationRadio?.artistPolice,
                                        WebkitTextStroke: configurationRadio?.textEffect === 'contour' && `1px ${configurationRadio?.artistColor}`,
                                    }}>{configurationRadio?.positionInversion ? lastArtiste : lastTitle}</span>
                                </div>
                            </div>
                        </div>
                        <div className='logo' style={{
                            zIndex: 3,
                            position: 'absolute',
                            top: configurationRadio?.logoPosition.includes('top') && '1%',
                            left: configurationRadio?.logoPosition.includes('left') && '1%',
                            bottom: configurationRadio?.logoPosition.includes('bottom') && '1%',
                            right: configurationRadio?.logoPosition.includes('right') && '1%',
                            height: `${configurationRadio?.logoH}%`,
                            width: `${configurationRadio?.logoW}%`,
                        }}>
                            <img
                                alt=''
                                className='logo' src={logoRadio} style={{ height: '100%', width: '100%', borderRadius: 10 }} />
                        </div>
                    </div>
                    {(previousSlide === index - 1 || (previousSlide === slideImagesTime.length - 1 && index === 0) || (previousSlide === currentSlide)) &&
                        <div
                            style={{
                                height: '100vh',
                                width: '100%',
                                animation: `${currentTransitionEffect}-out 1s ease-in-out`,
                                zIndex: -1
                            }}
                        // className={`combiner2-checked`}

                        >

                            <div style={{
                                backgroundImage: previousSlide === currentSlide ? `url(${slideImagesTime[previousSlide]?.replace(/\\/g, '/')})` : `url(${slideImagesTime[previousSlide]?.replace(/\\/g, '/')})`,
                                // backgroundImage: `url(${(currentSlide === 0 ? slideImagesTime[currentSlide] : slideImagesTime[previousSlide])?.replace(/\\/g, '/')})`,
                                // background:'yellow',
                                position: "absolute",
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%,-50%)',
                                width: "100%",
                                height: "100%",
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                // filter: 'blur(12px)',
                                filter: ` ${configurationRadio?.blackWhiteImage ? 'grayscale(1) blur(12px)' : ' grayscale(0) blur(12px)'}`,
                                zIndex: 0,
                                objectFit: 'cover',
                            }}></div>

                            <img
                                alt=''
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    filter: configurationRadio?.blackWhiteImage ? 'grayscale(1)' : 'grayscale(0)',
                                    objectFit: 'contain',
                                    zIndex: 0
                                }}
                                className={`image`}
                                src={index === 0 ? slideImagesTime[slideImagesTime.length - 1] : slideImagesTime[previousSlide]}
                            />

                            <div className='song-details' style={{
                                position: 'absolute',
                                // zIndex: 3,
                                top: configurationRadio?.walletPosition.includes('top') && '1%',
                                left: configurationRadio?.walletPosition.includes('left') && '1%',
                                bottom: configurationRadio?.walletPosition.includes('bottom') && '1%',
                                right: configurationRadio?.walletPosition.includes('right') && '1%',
                                width: `100%`,
                                height: `${configurationRadio?.walletH}%`,
                            }}>
                                <div className='song-image' style={{
                                    height: "100%",
                                    width: `${configurationRadio?.walletW}%`,
                                }}>
                                    <img className='' alt='WALLET' src={lastAlbumCover !== "" ? lastAlbumCover : logoRadio} style={{ height: '100%', width: '100%', borderRadius: 10 }} />
                                </div>
                                <div style={{
                                    position: 'relative',
                                    width: `20%`,
                                    height: '100%',
                                }}>
                                    <div className={`song-title-${configurationRadio?.textEffect}`} style={{
                                        position: 'absolute',
                                        top: configurationRadio?.titlePosition.includes('top') && 0,
                                        left: configurationRadio?.titlePosition.includes('left') && 0,
                                        bottom: configurationRadio?.titlePosition.includes('bottom') && 0,
                                        right: configurationRadio?.titlePosition.includes('right') && 0,
                                        transform: (configurationRadio?.titlePosition.includes('center') || configurationRadio?.titlePosition.length === 0) && 'translateY(-50%)',
                                        // top: (configurationRadio?.titlePosition.includes('center') || configurationRadio?.titlePosition.length === 0) && '50%',
                                    }}>
                                        <h1 style={{
                                            color: ["aucun", "ombre", "elevation"].includes(configurationRadio?.textEffect) ? configurationRadio?.titleColor : 'transparent',
                                            fontSize: configurationRadio?.titleSize,
                                            fontFamily: configurationRadio?.titlePolice,
                                            WebkitTextStroke: configurationRadio?.textEffect === 'contour' && `1px ${configurationRadio?.titleColor}`,
                                        }}>{configurationRadio?.positionInversion ? lastTitle : lastArtiste}</h1>

                                        <span style={{
                                            color: ["aucun", "ombre", "elevation"].includes(configurationRadio?.textEffect) ? configurationRadio?.artistColor : 'transparent',
                                            fontSize: configurationRadio?.artistSize,
                                            fontFamily: configurationRadio?.artistPolice,
                                            WebkitTextStroke: configurationRadio?.textEffect === 'contour' && `1px ${configurationRadio?.artistColor}`,
                                        }}>{configurationRadio?.positionInversion ? lastArtiste : lastTitle}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='logo' style={{
                                // zIndex: 3,
                                position: 'absolute',
                                top: configurationRadio?.logoPosition.includes('top') && '1%',
                                left: configurationRadio?.logoPosition.includes('left') && '1%',
                                bottom: configurationRadio?.logoPosition.includes('bottom') && '1%',
                                right: configurationRadio?.logoPosition.includes('right') && '1%',
                                height: `${configurationRadio?.logoH}%`,
                                width: `${configurationRadio?.logoW}%`,
                            }}>
                                <img alt='' className='logo' src={logoRadio} style={{ height: '100%', width: '100%', borderRadius: 10 }} />
                            </div>
                        </div>}

                </div>
            )
        });
    };

    const hexToRgba = (hex, alpha) => {
        let r = 0, g = 0, b = 0;
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    return (
        <div className="slide-container"
            style={{
                backgroundImage: `url(${logoRadio})`,
            }}
        >


            {!loading && !etatLoading && <>
                {renderSlides()
                }
                {(startTime && Lyrics.length && lyricsAllowed) &&
                    <div style={{
                        backgroundColor: hexToRgba(configurationRadio?.lyricsBgColor, 0.5),
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        height: "100%",
                        backgroundPosition: "bottom",
                        zIndex: 999,
                        objectFit: 'cover',
                        display: "flex", alignItems: 'center', justifyContent: 'center'
                    }}>
                        <LyricsDisplay Lyrics={Lyrics} startTime={startTime} delai={delai} Taille={configurationRadio?.lyricsSize} Color={configurationRadio?.lyricsColor} Police={configurationRadio?.lyricsPolice} />


                    </div>
                }
            </>}
            {(!etatLoading && !loading) && <audio autoPlay loop>
                <source src={streamUrl} type="audio/mpeg" />
            </audio>}

            {etatLoading &&
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}>
                    <img alt='' className='animation-disk' src={disque} height={500} width={500} />
                </div>}
        </div>
    );
};

export default NewAnimation;